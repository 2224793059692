import React, { useState } from "react";
import './Setting.css'

import "@tensorflow/tfjs";
import "../ObjectDetect/style.css";

function Setting() {
  const [selectedOption, setSelectedOption] = useState("enable");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      {/* vh-100 ensures the container takes 100% of the viewport height */}
      <div className="form-check form-switch twilio-option-card">
        <h2 className="twilio-h2">Do you want to disable Twillio</h2>
        <div className="twilio-option">
        <div style={{ textAlign: 'center' }}>
            <input
              type="radio"
              id="enable"
              name="enable"
              value="enable"
              checked={selectedOption === "enable"}
              onChange={handleOptionChange}
            />
            <label htmlFor="enable">Enable</label>
          </div>
          <div style={{ textAlign: 'center' }}>
            <input
              type="radio"
              id="disable"
              name="disable"
              value="Disable"
              checked={selectedOption === "disable"}
              onChange={handleOptionChange}
            />
            <label htmlFor="disable">Disable</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Setting;