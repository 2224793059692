import React, { useState } from 'react';
import './Register.css';
import {
  notification,
} from "antd";
import axios from "axios";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { HACKATHON_URL } from "../../Helper";

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(HACKATHON_URL+"/api/auth/register", {
        name: formData?.name,
        email: formData?.email,
        password: formData?.password,
        password_confirmation: formData?.confirmPassword
      })
      .then((response) => {
        if (response.status === 201) {
          notification.open({
            message: "User register successfully!",
            icon: (
              <CheckCircleOutlined
                style={{
                  color: "#d62b47",
                }}
              />
            ),
          });
          // navigate("/");
        } else {
          notification.open({
            message: "Registration failed!",
            icon: (
              <CloseCircleOutlined
                style={{
                  color: "#d62b47",
                }}
              />
            ),
          });
          console.error(response);
        }
      })
      .catch((err) => {
        notification.error({
          message: "Invalid credentials",
        });
        console.info("Get Error Edit State...");
      });
  };

  return (
    <div className="registration-container theme-bg-register">
      <div className="registration-form-container">
        <h2 className='sign-h3'>Register</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">Register</button>
        </form>
        <div className="text-center mt-4">
            <p className="font-weight-bold">
              <span className='sign-h3'>Already Have Account?</span> <a href="/login" className='font-weight-bold'>Signin</a>
            </p>
          </div>
      </div>
      
    </div>
  );
};

export default Register;
