import Dashboard from "views/Dashboard.js";
import ObjectDetector from "../src/components/ObjectDetect/Object";
import Setting from "../src/components/Settings/Setting";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard style={{ fontSize: '18px' }} />, // Increase font size here
    layout: "/admin",
  },
  {
    path: "/object_detector",
    name: "Object Detector",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-tap-02",
    component: <ObjectDetector style={{ fontSize: '18px' }} />, // Increase font size here
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-settings-gear-63",
    component: <Setting style={{ fontSize: '18px' }}/>,
    layout: "/admin",
  },
];
export default routes;
