import React from "react";

import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";
import "../ObjectDetect/style.css";
import axios from "axios";
import { HACKATHON_URL } from "../../Helper";
import Swal from 'sweetalert2'

//create class
class ObjectDetector extends React.Component {
  videoRef = React.createRef();
  canvasRef = React.createRef();


  constructor(props) {
    super(props);
    this.state = {
        object_name: null,
        requestMade: false, 
        selectedPrediction: null,
        predictions: [],
    }
  }

  // Create a function to capture and store the image with predictions
  captureAndStoreImage = () => {
    const canvas = this.canvasRef.current;
    const image = new Image();
    image.src = canvas.toDataURL("image/png");

    // Assuming you have an API endpoint to send the image data along with predictions
    const dataToSend = {
      image: image.src,
      predictions: this.state.predictions,
    };

    fetch(HACKATHON_URL+"/api/auth/object-detect", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => {
        if (response.ok) {
          // Handle successful API response here
          console.log("Image with predictions saved successfully!");
        } else {
          // Handle API error here
          console.error("Failed to save image with predictions.");
        }
      })
      .catch((error) => {
        console.error("Error while saving image with predictions:", error);
      });
  };
  //create user interface function aif web support with video
  componentDidMount() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "user",
          },
        })
        .then((stream) => {
          window.stream = stream;
          this.videoRef.current.srcObject = stream;
          return new Promise((resolve, reject) => {
            this.videoRef.current.onloadedmetadata = () => {
              resolve();
            };
          });
        });

      //load cocoSsd lib
      const modelPromise = cocoSsd.load();
      Promise.all([modelPromise, webCamPromise])
        .then((values) => {
          this.detectFrame(this.videoRef.current, values[0]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUnload);

    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
  }

  //if video detect model, display model & animation.
  detectFrame = (video, model) => {
    model.detect(video).then((predictions) => {
      this.renderPredictions(predictions);
      requestAnimationFrame(() => {
        this.detectFrame(video, model);
      });
    });
  };

  vibrateScreen = () => {
    const vibrateButton = document.getElementById('vibrateButton');
    const vibrationSound = document.getElementById('vibrationSound');

      // Check if the Vibration API is supported
      if ('vibrate' in navigator) {
          // Vibrate for 500ms
          navigator.vibrate(500);
      } else {
          console.log('Vibration API not supported');
      }
      console.warn({vibrationSound})
      // Play the vibration sound
      const audio = new Audio(process.env.PUBLIC_URL + '/vibration.mp3');
    audio.play();
    
  }

  //display grid box & model label name.
  renderPredictions = async (predictions) => {
    console.log({predictions})
    this.setState({ predictions });
    const canvas = this.canvasRef.current;

    if (!canvas) {
      return;
    }
  
    const ctx = canvas.getContext("2d");
    if (!ctx) {
        return;
      }
    ctx?.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    // Font options.
    const font = "16px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";
    predictions.forEach((prediction) => {
      const x = prediction.bbox[0];
      const y = prediction.bbox[1];
      const width = prediction.bbox[2];
      const height = prediction.bbox[3];    
      // Draw the bounding box.
      ctx.strokeStyle = "#00FFFF";
      ctx.lineWidth = 4;
      ctx.strokeRect(x, y, width, height);
      // Draw the label background.
      ctx.fillStyle = "#00FFFF";
      const textWidth = ctx.measureText(prediction.class).width;
      const textHeight = parseInt(font, 10); // base 10
      ctx.fillRect(x, y, textWidth + 4, textHeight + 4);
      let object_name = this.state.object_name
      console.log({object_name})
      if(prediction.class == "bottle" && !this.state.requestMade){
        this.setState({ requestMade: true });
        axios
        .post(HACKATHON_URL+"/api/auth/twilio/make-call", {
            toPhoneNumber: "+919983681765",
        })
        .then((response) => {
            this.vibrateScreen()
            Swal.fire({
                title: 'Knife Detected on your screen Calling on your number ....',
                iconHtml: '<img src='+process.env.PUBLIC_URL+'/siren.gif>',
                customClass: {
                  icon: 'no-border'
                }
              })
        })
        .catch((err) => {
          console.log({err})
        });
      }
    });

    predictions.forEach((prediction) => {
      const x = prediction.bbox[0];
      const y = prediction.bbox[1];
      // Draw the text last to ensure it's on top.
      ctx.fillStyle = "#000000";
      ctx.fillText(prediction.class, x, y);
    });
  };

  //video render settings
  render() {
    return (
      <div>
        <video
          className="size"
          autoPlay
          playsInline
          muted
          ref={this.videoRef}
          width="600"
          height="500"
        />
    
        <canvas
          className="size"
          ref={this.canvasRef}
          width="600"
          height="500"
        />
        <label>Name</label>
        <button onClick={this.captureAndStoreImage}>Save Image</button>
      </div>
    );
  }
}

export default ObjectDetector;
