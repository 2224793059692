import React, { useEffect, useState } from 'react';
import {
  notification,
} from "antd";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import './Login.css';
import axios from "axios";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

import logo_img from "../assets/logo-new.png";

function Login() {
  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    var colors = ["default", "purple", "blue", "green", "orange"];
    var randomIndex = Math.floor(Math.random() * colors.length);
    $(".theme-bg").addClass(colors[randomIndex]);
  }, []);

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const validateEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError(null);
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!validatePassword(newPassword)) {
      setPasswordError("Password must be at least 8 characters long");
    } else {
      setPasswordError(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError("Password must be at least 6 characters long");
      return;
    }

    setEmailError(null);
    setPasswordError(null);

    axios
      .post("https://1752-122-176-88-87.ngrok-free.app/api/auth/login", {
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("auth_token", response.data.access_token);
          localStorage.setItem("expire_time", response.data.expires_in);
          notification.open({
            message: "Successfully logged in!",
            icon: (
              <CheckCircleOutlined
                style={{
                  color: "#d62b47",
                }}
              />
            ),
          });
          navigate("/admin/dashboard");
        }else {
          notification.open({
            message: "Invalid credentials!",
            icon: (
              <CloseCircleOutlined
                style={{
                  color: "#d62b47",
                }}
              />
            ),
          });
          console.error(response);
        }
      })
      .catch((err) => {
        notification.error({
          message: "Invalid credentials",
        });
        console.info("Get Error Edit State...");
      });
  };

  return (
    <section className="login theme-bg">
      <div className="login_box">
        <div className="left">
          <div className="contact">
            <form onSubmit={handleSubmit} className='align-center'>
            <img src={logo_img} alt="logo" style={{ width: '250px' }} />
              <h3 className='sign-h3'>SIGN IN</h3>
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
                {emailError && <div className="error-message">{emailError}</div>}
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                 <span
                  className="password-toggle"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? (
                    <EyeInvisibleOutlined />
                  ) : (
                    <EyeOutlined />
                  )}
                </span>
                 {passwordError && (
                <div className="error-message">{passwordError}</div>
              )}
              <button type="submit" className="submit">
                Login
              </button>
            </form>
          </div>
          <div className="text-center mt-4">
            <p className="font-weight-bold">
              <span className='sign-h3'>Don't have an account?</span> <a href="/register" className='font-weight-bold'>Signup</a>
            </p>
          </div>
        </div>
        <div className="right">
            <div className="right-text">
                
            </div>
        </div>
      </div>
    </section>
  );
}

export default Login;